<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="900px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="green"
        dark
        v-bind="attrs"
        x-large
        v-on="on"
      >
        Saisir une activité
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Saisir une activité</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="form.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formDateAsText"
                    label="Date*"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="form.date"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  show-week
                  show-current
                  locale="fr-fr"
                  color="green lighten-1"
                  :max="todayISO"
                  @change="$refs.menu.save(form.date)"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="(form.date = yesterdayISO) && $refs.menu.save(form.date)"
                  >
                    Hier
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="(form.date = todayISO) && $refs.menu.save(form.date)"
                  >
                    Aujourd'hui
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              <v-autocomplete
                v-model="form.taskId"
                :items="activeTasks"
                label="Tâche*"
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="form.minutes"
                label="Durée*"
                required
                suffix="minutes"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-autocomplete
                v-model="form.customerId"
                :disabled="!enableCustomer"
                :items="activeCustomers"
                clearable
                label="Bénéficiaire"
                item-value="id"
                :item-text="item => item.last_name.toLocaleUpperCase() +' '+ item.first_name"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="form.serviceId"
                :items="activeServices"
                clearable
                label="Mesure"
                :item-text="item => item.name + (item.code ? ' [' + item.code + ']' : '')"
                item-value="id"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              offset-sm="6"
            >
              <v-autocomplete
                v-model="form.clientId"
                :items="activeClients"
                clearable
                label="Mandant"
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="form.note"
                filled
                name="input-7-4"
                label="Note"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>*indique les champs obligatoires</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          :loading="sending"
          @click="saveActivity().then(() => partialClearForm())"
        >
          Enregistrer et créer un autre
        </v-btn>
        <v-btn
          color="primary"
          :loading="sending"
          @click="saveActivity().then(() => closeDialog())"
        >
          Enregistrer et fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ActivityDialog',
    data () {
      return {
        now: new Date(),
        dialog: false,
        menu: false,
        sending: false,
        form: {
          customerId: null,
          serviceId: null,
          taskId: null,
          clientId: null,
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          minutes: 60,
          note: '',
          task: null,
          customer: null,
          service: null,
          client: null,
        },
      }
    },

    computed: {
      yesterdayISO () {
        const date = new Date(this.now.getTime())
        date.setDate(date.getDate() - 1)
        return date.toISOString().split('T')[0]
      },
      todayISO () {
        return this.now.toISOString().split('T')[0]
      },
      enableCustomer () {
        if (this.form.taskId) {
          const task = this.activeTasks.find(task => task.id === this.form.taskId)
          return task.has_related_customer
        } else {
          return true
        }
      },
      formDateAsText () {
        return (new Date(this.form.date)).toLocaleDateString('fr-CH', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
        })
      },
      ...mapGetters([
        'activeTasks',
        'activeCustomers',
        'activeServices',
        'activeClients',
      ]),
    },

    watch: {
      dialog (newValue) {
        if (newValue) {
          this.now = new Date()
          this.$store.dispatch('fetch_collections')
        }
      },
      'form.taskId' (newValue) {
        if (newValue) {
          const task = this.activeTasks.find(task => task.id === this.form.taskId)
          this.form.task = task
          if (task.default_service) {
            this.form.serviceId = task.default_service.id
          }
          if (task.default_client) {
            this.form.clientId = task.default_client.id
          }
        } else {
          this.form.task = null
        }
      },
      'form.customerId' (newValue) {
        if (newValue) {
          const customer = this.activeCustomers.find(customer => customer.id === this.form.customerId)
          this.form.customer = customer
          this.form.serviceId = customer.service.id
          this.form.clientId = customer.client.id
        } else {
          this.form.serviceId = null
        }
      },
      'form.serviceId' (newValue) {
        if (newValue) {
          const service = this.activeServices.find(service => service.id === this.form.serviceId)
          this.form.service = service
          if (service.client && !this.form.customer) {
            this.form.clientId = service.client.id
          }
        } else {
          this.form.serviceId = null
        }
      },
      'form.clientId' (newValue) {
        if (newValue) {
          this.form.client = this.activeClients.find(client => client.id === this.form.clientId)
        } else {
          this.form.clientId = null
        }
      },
    },

    methods: {
      partialClearForm () {
        this.form.taskId = null
        this.form.serviceId = null
        this.form.customerId = null
        this.form.clientId = null
        this.form.note = null
        this.sending = false
      },
      closeDialog () {
        this.dialog = false
        this.form.date = this.todayISO
        this.form.minutes = 60
        this.form.taskId = null
        this.form.serviceId = null
        this.form.customerId = null
        this.form.clientId = null
        this.form.note = null
        this.sending = false
      },
      saveActivity () {
        this.sending = true
        const me = localStorage.getItem('me')
        let referrers = []
        if (this.form.customer?.referrers) {
          referrers = this.form.customer.referrers.map(ref => ref.directus_users_id.id)
        }
        const isReferrer = referrers.includes(me)
        return fetch(
          'https://api.chronophage.xenote.ch/items/activities',
          {
            method: 'POST',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
            body: JSON.stringify({
              minutes: this.form.minutes,
              date: this.form.date,
              task: this.form.taskId,
              customer: this.form.customerId,
              client: this.form.clientId,
              service: this.form.serviceId,
              user: me,
              user_is_referrer: isReferrer,
              note: this.form.note,
            }),
          },
        ).then(response => {
          if (this.$route.name === 'Tableau de Bord') this.$reloadDashboard()
          if (this.$route.name === 'Mes Activités') this.$reloadMyActivities()
          return response
        })
      },
    },
  }
</script>

<style scoped>

</style>
